// import * as React from "react";
// import * as yup from "yup";
import DateFnsUtils from '@date-io/date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
// import { Button } from 'primereact/button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { filter, forkJoin, map, of, switchMap, tap, throwIfEmpty } from 'rxjs';
import * as yup from 'yup';
import { AgentsService } from '../../remote-api/api/agents-services';
import { ClientService, ProspectService } from '../../remote-api/api/client-services';
import { InvoiceService } from '../../remote-api/api/invoice-services';
import { AddressService } from '../../remote-api/api/master-services';
import { PlanService } from '../../remote-api/api/plan-services';
import { ProductService } from '../../remote-api/api/product-services';
import { QuotationService } from '../../remote-api/api/quotation-services';
import { TaxService } from '../../remote-api/api/tax-services';
import throwErrorMessageEvent from '../../util/message.event.producer';
import InvoiceAgentModal from './modals/invoice.agent.modal.component';
import InvoiceClientModal from './modals/invoice.client.modal.component';
import { Button } from '@material-ui/core';
import Asterisk from '../../shared-components/components/red-asterisk';

const validationSchema = yup.object({
  name: yup.string('Enter your Name').required('Name is required'),
  type: yup.string('Choose Agent type').required('Agent Type is required'),
  contact: yup
    .string('Enter your Contact Number')
    .required('Contact number is required')
    .test('len', 'Must be exactly 10 digit', val => val.length === 10),
  // .matches(new RegExp('[0-9]{10}'),'Contact number is not valid'),
  email: yup.string('Enter your email').email('Enter a valid email'),
  natureOfAgent: yup.string('Enter Nature of Agent').required('Agent Nature is required'),
});

const invoiceTypeOptions = [
  {
    value: 'SELF_FUND',
    label: 'Self Fund',
  },
  {
    value: 'INDEMNITY',
    label: 'Indemnity',
  },
  {
    value: 'CORPORATE_BUFFER',
    label: 'Corporate Buffer/SBP',
  },
];

const TypographyStyle2 = {
  fontSize: '13px',
  fontWeight: '500',
  alignItems: 'end',
  display: 'flex',
  textTransform: 'capitalize',
  width: '150px',
  marginLeft: '10px',
  opacity: '0.65',
};

const TypographyStyle1 = {
  fontSize: '16px',
  fontWeight: '700',
  textTransform: 'capitalize',
  opacity: '0.75',
};

const invoiceservice = new InvoiceService();
const taxservice = new TaxService();
const productservice = new ProductService();
const planservice = new PlanService();
const agentservice = new AgentsService();
const clientservice = new ClientService();
const prospectservice = new ProspectService();
const addressservice = new AddressService();
const quotationservice = new QuotationService();

let pdt$ = productservice.getProducts();
let ts$ = taxservice.getTaxes();
let addr$ = addressservice.getAddressConfig();

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    width: '90%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  AccordionSummary: {
    backgroundColor: theme.palette.background.default,
  },

  disabled: {},
}));

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

export default function InvoiceDetails(props) {
  const query2 = useQuery1();
  const invoiceNumber = localStorage.getItem('InvoiceNumber');
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [productList, setProductList] = React.useState([]);
  const [planList, setPlanList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [openClientModal, setOpenClientModal] = React.useState(false);
  const [openAgentModal, setOpenAgentModal] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [taxList, setTaxList] = React.useState([]);
  const [clientData, setClientData] = React.useState({
    clientName: '',
    clientMobile: '',
    clientEmail: '',
    clientId: '',
    clientType: '',
  });

  const [addressConfig, setAddressConfig] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [revertedMessage, setRevertedMessage] = React.useState(false);
  const [headerTitle, setHeaderTitle] = React.useState('Invoice Management- Create Invoice');
  const [invoiceType, setInvoiceType] = React.useState('SELF_FUND');
  const [clientType, setClientType] = React.useState();
  const [fundDetailsData, setFundDetailsData] = React.useState({
    availableFundBalanceAsOn: 0,
    adminFees: 0,
    careFees: 0,
    topupAmount: 0,
    depositAmount: 0,
    totalInvoiceAmount: 0,
  });
  let [quotation, setQuotation] = React.useState({});
  const [expandClientDetails, setExpandClientDetails] = React.useState(false);
  const [expandInvoiceDetails, setExapandInvoiceDetails] = React.useState(false);

  const handleInvoiceDate = date => {
    setSelectedDate(date);

    const timestamp = new Date(date).getTime();

    formik.setFieldValue('invoiceDate', timestamp);
  };

  const formik = useFormik({
    initialValues: {
      invoiceDate: new Date().getTime(),
      invoiceType: 'invoiceFromFund',
      quotationNumber: '',
      clientOrProspectId: '',
      clientOrProspectType: '',
      product: '',
      plan: '',
      description: '',
      planData: '',
      productData: '',
      categorydata: [],
      discountType: 'PERCENTAGE',
      discountValue: 0,
      loadingType: 'PERCENTAGE',
      loadingValue: 0,
      discountAmount: 0,
      loadingAmount: 0,
      totalPremiumAmount: 0,
      totalTaxAmount: 0,
      totalAmountWithoutTax: 0,
      totalAmountWithTax: 0,
    },
    // validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit();
    },
  });

  // useEffect(() => {
  //   let subscription = addr$.subscribe(result => {
  //     if (result && result.length !== 0) {
  //       result.forEach((prop, i) => {
  //         prop.addressConfigurationFieldMappings.forEach((field, j) => {
  //           // let fname = "field"+i+j;
  //           field['value'] = '';
  //           if (field.sourceId !== null && field.sourceId !== '') {
  //             field['sourceList'] = [];
  //           }
  //           if (field.type === 'dropdown' && field.sourceId === null) {
  //             if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
  //               field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
  //             }
  //             // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
  //             //   field['sourceList'] = [];
  //             // }
  //           }
  //         });
  //       });

  //       setAddressConfig(result);

  //       result.forEach((prop, i) => {
  //         prop.addressConfigurationFieldMappings.map((field, j) => {
  //           //   frmObj[field.fieldName] = field.defaultValue;
  //           if (field.dependsOn == '' && field.type == 'dropdown' && field.modifyApiURL) {
  //             addressservice.getSourceList(field.modifyApiURL).subscribe(res => {
  //               // field.sourceList =res.content;
  //               const list = [...result];
  //               list[i].addressConfigurationFieldMappings[j].sourceList = res.content;
  //               setAddressConfig(list);
  //               // frmLst[field.fieldName] = res.content;
  //             });
  //           }
  //         });
  //       });
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [addr$]);

  const handleopenClientModal = () => {
    setOpenClientModal(true);
  };

  const handleOpenAgentModal = () => {
    setOpenAgentModal(true);
  };

  const handleCloseAgentModal = () => {
    setOpenAgentModal(false);
  };

  const handleCloseClientModal = () => {
    setOpenClientModal(false);
  };

  const fetchQuotationDetails = () => {
    let payload;
    if (query2.get('mode') === 'edit') {
      payload = {
        quotationNo: id,
      };
    } else {
      payload = {
        quotationNo: formik.values.quotationNumber,
      };
    }

    quotationservice
      .getQuoationDetails(payload)
      .pipe(
        filter(qPage => {
          let result = !!qPage.content.length;
          if (!result) {
            throwErrorMessageEvent('', 'Quotation not found');
          }
          return result;
        }),
        map(qPage => qPage.content[0]),
        filter(qu => {
          let result = qu.quotationStatus === 'APPROVED';

          if (!result) {
            throwErrorMessageEvent('', 'Quotation not approved');
          }
          if (qu.isInvoiceGenerated === true) {
            throwErrorMessageEvent('', 'Invoice already created');
          }
          if (result && qu.renewalPolicyId) {
            setHeaderTitle("'Invoice Management- Create Renewal Invoice'");
          } else {
            setHeaderTitle("'Invoice Management- Create Invoice'");
          }
          return result;
        }),
        switchMap(qu => {
          let pd$ = productservice.getProductDetails(qu.productId);
          let plan$ = planservice.getPlanDetails(qu.planId);
          let payload = {
            prospectId: qu.prospectId,
            summary: false,
            active: true,
          };

          let client$ = clientservice.getClients(payload);

          return forkJoin({ qu: of(qu), pd: pd$, pl: plan$, clientPage: client$ });
        }),
      )
      .subscribe(response => {
        let { qu, pd, pl, clientPage } = response;

        let prodObj = {
          name: pd.productBasicDetails.name,
          id: pd.id,
        };
        setExapandInvoiceDetails(true);

        formik.setFieldValue('productData', prodObj);
        formik.setFieldValue('product', pd.id);

        formik.setFieldValue('planData', pl);
        formik.setFieldValue('plan', pl.id);

        if (qu.categoryMemberHeadCountPremiumAmounts) {
          const keys = Object.keys(qu.categoryMemberHeadCountPremiumAmounts);
          let cdata = [];
          keys.forEach((key, index) => {
            cdata.push({
              name: key,
              id: index,
              description: '',
              noOfMembers: qu.categoryMemberHeadCountPremiumAmounts[key]['headCount'],
              premiumAmount: qu.categoryMemberHeadCountPremiumAmounts[key]['premiumAmount'],
            });
          });
          formik.setFieldValue('categorydata', cdata);
          formik.setFieldValue('totalPremiumAmount', qu.totalPremium);
          calculateData(cdata);
        }

        setQuotation(qu);
        setProductList([prodObj]);
        setPlanList([pl]);

        if (clientPage.content && clientPage.content.length) {
          let client = clientPage.content[0];
          setClientData({
            clientData,
            clientName: client.clientBasicDetails.displayName,
            clientMobile: client.clientBasicDetails.contactNos[0].contactNo,
            clientEmail: client.clientBasicDetails.emails ? client.clientBasicDetails.emails[0].emailId : [],
            clientId: client.id,
            clientType: 'Client',
          });
          populateDynamicAddress(client, addressConfig);
        }
      });
  };

  const handleSubmitClientModal = item => {
    setClientData({
      ...setClientData,
      clientName: item.name,
      clientMobile: item.contactNo,
      clientEmail: item.email,
      clientId: item.id,
      clientType: item.clientType,
    });
    setClientType(item?.clientBasicDetails?.clientTypeCd);
    populateDynamicAddress(item, addressConfig);
    setOpenClientModal(false);
  };

  const callAPiFunc = (field, prop, resultarr, addrrList) => {
    addrrList.forEach((pr, i) => {
      pr.addressConfigurationFieldMappings.forEach((fi, j) => {
        if (fi.fieldName === prop.dependOnfields[0]) {
          resultarr.push(fi.value);
          if (pr.dependOnfields !== null) {
            callAPiFunc(fi, pr, resultarr, addrrList);
          }
        }
      });
    });

    return resultarr;
  };

  const populateDynamicAddress = (item, addressConfigList) => {
    if (addressConfigList && addressConfigList.length != 0) {
      let addrrList = [...addressConfigList];
      if (item.clientAddress) {
        item.clientAddress.addresses.forEach(val => {
          addrrList.forEach((prop, i) => {
            prop.addressConfigurationFieldMappings.forEach((field, j) => {
              if (Object.keys(val.addressDetails)[0] === field.fieldName) {
                field['value'] = val.addressDetails[field.fieldName];
              }
            });
          });
        });

        setAddressConfig(prv => [...new Set([...addressConfigList, ...addrrList])]);

        addrrList.forEach((prop, i) => {
          prop.addressConfigurationFieldMappings.forEach((field, j) => {
            if (field.type === 'dropdown' && field.dependsOn !== '' && prop.dependOnfields !== null) {
              let arr = [];
              let dArr = callAPiFunc(field, prop, arr, addrrList);

              const word = '{code}';
              let apiURL = field.modifyApiURL;
              dArr.forEach(cd => {
                apiURL =
                  apiURL.slice(0, apiURL.lastIndexOf(word)) + apiURL.slice(apiURL.lastIndexOf(word)).replace(word, cd);
              });
              addressservice.getSourceList(apiURL).subscribe(res => {
                addrrList[i].addressConfigurationFieldMappings[j].sourceList = res.content;

                setAddressConfig(addrrList);
              });
            }
          });
        });
      }
    }
  };

  const changeCommision = (e, i) => {
    const { name, value } = e.target;
    const list = [...agentsList];
    list[i][name] = value;
    setAgentsList(list);
    calculateFinalValue(list, i);
  };

  const calculateFinalValue = (list, i) => {
    list[i]['finalValue'] = (Number(list[i]['commissionValue']) * Number(formik.values.totalAmountWithoutTax)) / 100;
    setAgentsList(list);
  };

  const calculateAgentValues = totalAmountWithoutTax => {
    const list = [...agentsList];
    list.forEach(ele => {
      ele['finalValue'] = (Number(ele.commissionValue) * Number(totalAmountWithoutTax)) / 100;
    });
    setAgentsList(list);
  };

  const handleAgentModalSubmit = selectedAgents => {
    // const finalArr = [...agentsList,...selectedAgents];
    setAgentsList(selectedAgents);
    setOpenAgentModal(false);
  };

  const handleSubmit = () => {
    let payload = {
      invoiceType: formik.values.invoiceType,
      invoiceDate: new Date(selectedDate).getTime(),
      clientOrProspectId: clientData.clientId,
      clientOrProspectType: clientData.clientType,
      productId: formik.values.product,
      planId: formik.values.plan,
      totalBeforeDiscountAndLoadingAmount: Number(formik.values.totalPremiumAmount),
      discountType: formik.values.discountType,
      discountEnterValue: formik.values.discountValue,
      totalDiscount: Number(formik.values.discountAmount),
      loadingType: formik.values.loadingType,
      loadingEnterValue: formik.values.loadingValue,
      totalLoading: Number(formik.values.loadingAmount),
      totalAfterDiscountAndLoadingAmount: Number(formik.values.totalAmountWithoutTax),
      totalTaxAmount: Number(formik.values.totalTaxAmount),
      totalAmountWithTax: Number(formik.values.totalAmountWithTax),
      quotationId: formik.values.invoiceType === 'invoiceFromQuotation' ? quotation.id : null,
    };
    let invArr = [];
    formik.values.categorydata.forEach(ele => {
      invArr.push({ categoryId: ele.id, noOfMenber: ele.noOfMembers, premiumAmount: Number(ele.premiumAmount) });
    });
    payload['invoiceCategories'] = invArr;

    let invAgents = [];
    agentsList.forEach(ag => {
      invAgents.push({
        agentId: ag.agentId,
        commissionType: ag.commissionType,
        commissionValue: ag.commissionValue,
        finalValue: ag.finalValue,
      });
    });

    payload['invoiceAgents'] = invAgents;

    let invTaxes = [];

    taxList.forEach(tx => {
      if (tx.checked) {
        invTaxes.push({
          taxAmount: tx.taxVal,
          taxId: tx.id,
        });
      }
    });
    payload['invoiceTaxes'] = invTaxes;

    invoiceservice.saveInvoice(payload).subscribe(res => {
      history.push(`/invoices?mode=viewList`);
      // window.location.reload();
    });
  };

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  //tax API
  const useObservable2 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            ele['checked'] = false;
            ele['taxVal'] = 0;
          });
        }

        result.content.sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        });
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  //product API
  const useObservable3 = (observable, setter) => {
    useEffect(() => {
      let pageRequest = {
        page: 0,
        // size: 100000000,
        summary: true,
        active: true,
      };
      let subscription = productservice.getProducts().subscribe(result => {
        let tableArr = [];

        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.productBasicDetails.name,
              id: ele.id,
            });
          });
        }
        setter(tableArr);
        populateProduct(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  // useObservable(ct$, setCardTypes);
  // useObservable(ft$, setFeesTypes);
  useObservable2(ts$, setTaxList);
  // useObservable2(ps$, setPlanList);
  useObservable3(pdt$, setProductList);

  const populateProduct = prodList => {
    setExapandInvoiceDetails(true);
    setExpandClientDetails(true);
    if (query2.get('mode') === 'edit') {
      return;
    }
    if (id) {
      invoiceservice.getInvoiceDetails(id).subscribe(res => {
        formik.setFieldValue('invoiceDate', res.invoiceDate);
        formik.setFieldValue('invoiceType', res.invoiceType);
        formik.setFieldValue('clientOrProspectId', res.clientOrProspectId);
        formik.setFieldValue('clientOrProspectType', res.clientOrProspectType);
        formik.setFieldValue('discountType', res.discountType);
        formik.setFieldValue('discountValue', res.discountEnterValue);
        formik.setFieldValue('loadingType', res.loadingType);
        formik.setFieldValue('loadingValue', res.loadingEnterValue);
        formik.setFieldValue('discountAmount', res.totalDiscount);
        formik.setFieldValue('loadingAmount', res.totalLoading);
        formik.setFieldValue('totalPremiumAmount', res.totalBeforeDiscountAndLoadingAmount);
        formik.setFieldValue('totalAmountWithoutTax', res.totalAfterDiscountAndLoadingAmount);
        formik.setFieldValue('totalTaxAmount', res.totalTaxAmount);
        formik.setFieldValue('totalAmountWithTax', res.totalAmountWithTax);
        if (res.quotationId) {
          formik.setFieldValue('quotationNumber', res.quotationId);
        }
        setRevertedMessage(res.reverted);

        setSelectedDate(new Date(res.invoiceDate));
        // prodList.forEach(p => {
        // if (p.id === res.productId) {
        productservice.getProductDetails(res.productId).subscribe(p => {
          let productObj = {
            name: p?.productBasicDetails?.name,
            id: p?.id,
          };

          formik.setFieldValue('productData', productObj);
          formik.setFieldValue('product', res.productId);
          planservice.getPlanFromProduct(res.productId).subscribe(pl => {
            if (pl.length > 0) {
              setPlanList(pl);
              pl.forEach(plan => {
                if (plan.id === res.planId) {
                  formik.setFieldValue('planData', plan);
                  formik.setFieldValue('plan', res.planId);
                  planservice.getCategoriesFromPlan(res.planId).subscribe(catList => {
                    if (catList.length > 0) {
                      let catArr = [];
                      catList.forEach(cat => {
                        cat['noOfMembers'] = 0;
                        cat['premiumAmount'] = 0;
                        catArr.push(cat);
                      });
                      setCategoryList(catArr);
                      let cData = [];
                      res.invoiceCategories.forEach(invCat => {
                        catArr.forEach(cate => {
                          if (Number(invCat.categoryId) === cate.id) {
                            cate['noOfMembers'] = invCat.noOfMenber;
                            cate['premiumAmount'] = invCat.premiumAmount;
                            cData.push(cate);
                          }
                        });
                      });
                      formik.setFieldValue('categorydata', cData);
                    }
                  });
                }
              });
            }
          });
          // }
        });

        taxservice.getTaxes().subscribe(result => {
          if (result.content) {
            result.content.forEach(re => {
              re['checked'] = false;
              re['taxVal'] = 0;
            });
          }

          let txList = [];
          res.invoiceTaxes.forEach(inv => {
            if (result.content && result.content.length > 0) {
              result.content.forEach(tx => {
                if (tx.id === inv.taxId) {
                  tx['checked'] = true;
                  tx['taxVal'] = inv.taxAmount;
                }
              });
            }
          });
          result.content.sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          });
          setTaxList(result.content);
        });

        let agentIDs = [];
        // res.invoiceAgents.forEach(invAgIds => {
        //     agentIDs.push(invAgIds.agentId);
        // })

        let pageRequest = {
          page: 0,
          size: 100,
          summary: true,
          active: true,
          // agentIds:agentIDs
        };
        let invoiceAgentList = [];
        agentservice.getAgents(pageRequest).subscribe(agentdta => {
          res.invoiceAgents.forEach(invAgIds => {
            if (agentdta.content && agentdta.content.length > 0) {
              agentdta.content.forEach(ag => {
                if (invAgIds.agentId === ag.id) {
                  invoiceAgentList.push({
                    agentId: invAgIds.agentId,
                    commissionType: invAgIds.commissionType,
                    commissionValue: invAgIds.commissionValue,
                    finalValue: invAgIds.finalValue,
                    name: ag.agentBasicDetails.name,
                  });
                }
              });
            }
          });
          setAgentsList(invoiceAgentList);
        });

        if (res.clientOrProspectType === 'Client') {
          clientservice.getClientDetails(res.clientOrProspectId).subscribe(cdata => {
            setClientData({
              clientName: cdata.clientBasicDetails.displayName,
              clientEmail: cdata.clientBasicDetails.contactNos[0].contactNo,
              clientMobile: cdata.clientBasicDetails.emails[0].emailId,
              clientId: cdata.id,
              clientType: 'Client',
            });
            addr$.subscribe(result => {
              if (result && result.length !== 0) {
                result.forEach((prop, i) => {
                  prop.addressConfigurationFieldMappings.forEach((field, j) => {
                    // let fname = "field"+i+j;
                    // field['value'] = '';
                    if (field.sourceId !== null && field.sourceId !== '') {
                      field['sourceList'] = [];
                    }
                    if (field.type === 'dropdown' && field.sourceId === null) {
                      if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
                        field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
                      }
                      // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
                      //   field['sourceList'] = [];
                      // }
                    }
                  });
                });

                setAddressConfig(result);
                //
                result.forEach((prop, i) => {
                  prop.addressConfigurationFieldMappings.map((field, j) => {
                    //   frmObj[field.fieldName] = field.defaultValue;
                    if (field.dependsOn == '' && field.type == 'dropdown' && field.modifyApiURL) {
                      addressservice.getSourceList(field.modifyApiURL).subscribe(res => {
                        // field.sourceList =res.content;
                        const list = [...result];
                        result[i].addressConfigurationFieldMappings[j].sourceList = res.content;
                        // frmLst[field.fieldName] = res.content;
                        populateDynamicAddress(cdata, result);
                      });
                    }
                  });
                });
              }
            });
          });
        }

        if (res.clientOrProspectType === 'Prospect') {
          prospectservice.getProspectDetails(res.clientOrProspectId).subscribe(cdata => {
            setClientData({
              clientName: cdata.displayName,
              clientEmail: cdata.emailId,
              clientMobile: cdata.mobileNo,
              clientId: cdata.id,
              clientType: 'Prospect',
            });
          });
        }
      });
    }
  };

  const handleLoadingChange = e => {
    const { name, value } = e.target;
    formik.setFieldValue('loadingValue', value);

    // let lv = formik.values.loadingValue;
    let dv = formik.values.discountValue;
    let tpa = formik.values.totalPremiumAmount;
    calculateTotalAmoutWithoutTax(value, dv, tpa);
  };

  const handleDiscountChange = e => {
    const { name, value } = e.target;
    formik.setFieldValue('discountValue', value);

    let lv = formik.values.loadingValue;
    // let dv = formik.values.discountValue;
    let tpa = formik.values.totalPremiumAmount;
    calculateTotalAmoutWithoutTax(lv, value, tpa);
  };

  const calculateTotalAmoutWithoutTax = (loadingVal, discountVal, premiumTotal) => {
    let la = (Number(loadingVal) / 100) * premiumTotal;
    let da = (Number(discountVal) / 100) * premiumTotal;

    if (formik.values.loadingType === 'PERCENTAGE') {
      formik.setFieldValue('loadingAmount', la);
    }
    if (formik.values.loadingType === 'FIXED') {
      la = Number(loadingVal);
      formik.setFieldValue('loadingAmount', la);
    }
    if (formik.values.discountType === 'PERCENTAGE') {
      formik.setFieldValue('discountAmount', da);
    }
    if (formik.values.discountType === 'FIXED') {
      da = Number(discountVal);
      formik.setFieldValue('discountAmount', da);
    }

    let at = premiumTotal + la - da;
    formik.setFieldValue('totalAmountWithoutTax', at);
    calculateAgentValues(at);
    calculateTax(taxList, at);
  };

  //called after category select or premium amount change
  const calculateData = list => {
    let total = 0;
    if (list.length > 0) {
      list.forEach(ele => {
        total = total + Number(ele.premiumAmount);
      });
    }
    formik.setFieldValue('totalPremiumAmount', total);

    let lv = formik.values.loadingValue;
    let dv = formik.values.discountValue;

    calculateTotalAmoutWithoutTax(lv, dv, total);
  };

  const calculateTax = (txlist, totalAmountWithoutTax) => {
    txlist.forEach(ele => {
      if (ele.checked) {
        if (ele.type === 'PERCENTAGE') {
          ele.taxVal = (Number(ele.value) * Number(totalAmountWithoutTax)) / 100;
        }
        if (ele.type === 'FIXED') {
          ele.taxVal = Number(ele.value);
        }
      }
    });
    setTaxList(txlist);
    let grandTotal = Number(totalAmountWithoutTax);
    let tt = 0;
    txlist.forEach(v => {
      if (v.checked) {
        grandTotal = grandTotal + Number(v.taxVal);
        tt = tt + Number(v.taxVal);
      }
    });

    formik.setFieldValue('totalAmountWithTax', grandTotal);

    formik.setFieldValue('totalTaxAmount', tt);
  };

  const handleFieldChecked = (e, index) => {
    const { name, checked } = e.target;
    const list = [...taxList];
    list[index][name] = checked;
    setTaxList(list);
    calculateTax(list, formik.values.totalAmountWithoutTax);
  };

  React.useEffect(() => {
    if (query2.get('mode') === 'view' && id) {
      populateData(id);
    }

    if (query2.get('mode') === 'edit' && id) {
      formik.setFieldValue('quotationNo', id);
      formik.setFieldValue('invoiceType', 'invoiceFromQuotation');
      fetchQuotationDetails();
    }
  }, [id]);

  const populateData = id => {};

  const getPlans = productId => {
    planservice.getPlanFromProduct(productId).subscribe(res => {
      if (res.length > 0) {
        setPlanList(res);
      }
    });
  };

  const getCategories = planid => {
    planservice.getCategoriesFromPlan(planid).subscribe(res => {
      if (res.length > 0) {
        let arr = [];
        res.forEach(ele => {
          ele['noOfMembers'] = 0;
          ele['premiumAmount'] = 0;
          arr.push(ele);
        });
        setCategoryList(arr);
      }
    });
  };

  const handleClose = () => {
    history.push(`/invoices?mode=viewList`);
    // window.location.reload();
  };

  const handlePlanChange = (e, value) => {
    formik.setFieldValue('planData', value);
    formik.setFieldValue('plan', value?.id);
    getCategories(value?.id);
  };

  const handleProductChange = (e, value) => {
    formik.setFieldValue('productData', value);
    formik.setFieldValue('product', value?.id);
    getPlans(value?.id);
  };

  const handleCategorySelect = (e, value) => {
    formik.setFieldValue('categorydata', value);
    calculateData(value);
  };

  const changeCategoryData = (e, i) => {
    const { name, value } = e.target;
    let list = formik.values.categorydata;
    list[i][name] = value;
    formik.setFieldValue('categorydata', list);
    calculateData(list);
  };

  const changePremiumAmount = (e, i) => {
    const { name, value } = e.target;
    let list = formik.values.categorydata;
    list[i][name] = value;
    formik.setFieldValue('categorydata', list);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {query2.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: '#000',
            fontSize: '18px',
            fontWeight: 600,
          }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {headerTitle}
          </span>
        </Grid>
      ) : null}
      {/* {query2.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: 'inherit',
            fontSize: '18px',
            fontWeight: 600,
          }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            Invoice Management- Create Invoice
          </span>
        </Grid>
      ) : null} */}
      {query2.get('mode') !== 'edit' && (
        <Paper elevation="none">
          <Box p={3} my={2}>
            <Grid container alignItems="flex-end" spacing={3} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* <Grid container justify="space-around"> */}
                    <KeyboardDatePicker
                      views={['year', 'month', 'date']}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Invoice Date"
                      value={selectedDate}
                      disabled={query2.get('mode') === 'view' ? true : false}
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                      onChange={handleInvoiceDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change ing date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                {/* <FormControl classes={classes.formControl}> */}
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="invoiceType"
                    value={formik.values.invoiceType}
                    onChange={formik.handleChange}>
                    <FormControlLabel
                      value="invoiceFromQuotation"
                      control={<Radio color="primary" size="small" />}
                      label="Invoice from Quotation"
                      labelPlacement="end"
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                    <FormControlLabel
                      value="invoiceWithoutQuotation"
                      control={<Radio color="primary" size="small" />}
                      label="Invoice without Quotation"
                      labelPlacement="end"
                      disabled={query2.get('mode') === 'view' ? true : false}
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                {query2.get('mode') !== 'view' &&
                  (formik.values.invoiceType === 'invoiceWithoutQuotation' ||
                    formik.values.invoiceType === 'invoiceFromFund') && (
                    <Button
                      type="button"
                      className="mr-2 mb-2"
                      variant="contained"
                      color="secondary"
                      onClick={handleopenClientModal}>
                      Search Client
                    </Button>
                  )}
                {/* {query2.get('mode') === 'view' && revertedMessage && (
                <span style={{ color: 'red', fontWeight: 'bold' }}>REVERTED</span>
              )} */}
                <InvoiceClientModal
                  setOpenClientModal={setOpenClientModal}
                  openClientModal={openClientModal}
                  handleCloseClientModal={handleCloseClientModal}
                  handleSubmitClientModal={handleSubmitClientModal}
                />
              </Grid>
            </Grid>

            {formik.values.invoiceType === 'invoiceFromQuotation' && (
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    name="quotationNumber"
                    label="Quotation Number"
                    value={formik.values.quotationNumber}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  {query2.get('mode') !== 'view' ? (
                    <Button type="button" variant="contained" color="primary" onClick={fetchQuotationDetails}>
                      Search Quotation
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            )}
          </Box>
        </Paper>
      )}
      <Paper elevation={0}>
        <Box p={3} my={2}>
          <Accordion expanded={expandClientDetails} elevation="none">
            <AccordionSummary
              className={classes.AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExpandClientDetails(!expandClientDetails)}>
              <Typography className={classes.heading}>Client Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="clientName"
                      readonly={true}
                      label="Name"
                      value={clientData.clientName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="clientEmail"
                      readonly={true}
                      label="Email"
                      value={clientData.clientEmail}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="clientMobile"
                      label="Contact"
                      readonly={true}
                      value={clientData.clientMobile}
                    />
                  </FormControl>
                </Grid>
                {addressConfig && addressConfig.length !== 0 && (
                  <Grid item xs={12} container style={{ marginBottom: '20px' }}>
                    {addressConfig.map((prop, i) => {
                      return prop.addressConfigurationFieldMappings.length !== 1 ? (
                        <Grid item xs={12} sm={6} md={3} key={i + Math.random()}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                              {prop.levelName}
                            </InputLabel>
                            {prop.iButtonRequired === 'true' && (
                              <Tooltip title={prop.iButtonMessage} placement="top">
                                <InfoOutlinedIcon style={{ fontSize: 'medium' }} />
                              </Tooltip>
                            )}
                          </div>
                          {prop.addressConfigurationFieldMappings.map((field, j) => {
                            return (
                              <div key={j + Math.random()}>
                                {field.type === 'dropdown' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      name={field.fieldName}
                                      id="demo-simple-select"
                                      required={field.required === 'true' ? true : false}
                                      style={{ marginRight: '8px' }}
                                      value={field.value}
                                      readOnly>
                                      {field.sourceList.map(ele => {
                                        return (
                                          <MenuItem value={ele.code} key={ele.code}>
                                            {ele.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                )}
                                {field.type === 'textbox' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      id="standard-basic"
                                      name={field.fieldName}
                                      type={field.dataType === 'numeric' ? 'number' : 'text'}
                                      style={{ marginTop: '8px' }}
                                      value={field.value}
                                      readOnly
                                    />
                                  </FormControl>
                                )}

                                {field.type === 'textarea' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      required={field.required === 'true' ? true : false}
                                      id="standard-multiline-flexible"
                                      multiline
                                      name={field.fieldName}
                                      rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                                      value={field.value}
                                      readOnly
                                    />
                                  </FormControl>
                                )}
                                {field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      id="standard-basic"
                                      name={field.fieldName}
                                      // value={field.defaultValue}
                                      defaultValue={field.defaultValue}
                                      readonly
                                      style={{ marginTop: '10px', marginRight: '8px', width: '15%' }}
                                      size="small"
                                    />
                                  </FormControl>
                                )}
                              </div>
                            );
                          })}
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={3} key={i + 50}>
                          {prop.addressConfigurationFieldMappings.map((field, j) => {
                            return (
                              <div key={j + Math.random()}>
                                {field.type === 'dropdown' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                      {prop.levelName}
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      name={field.fieldName}
                                      required={field.required === 'true' ? true : false}
                                      id="demo-simple-select"
                                      value={field.value}
                                      readOnly>
                                      {field.customValuePresent === 'CUSTOM' &&
                                        field.sourceList.map(ele => {
                                          return (
                                            <MenuItem value={ele.id} key={ele.id}>
                                              {ele.value}
                                            </MenuItem>
                                          );
                                        })}
                                      {field.customValuePresent === 'DYNAMIC' &&
                                        field.sourceList.map(ele => {
                                          return (
                                            <MenuItem value={ele.code} key={ele.code}>
                                              {ele.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                )}

                                {field.type === 'textbox' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      required={field.required === 'true' ? true : false}
                                      id="standard-basic"
                                      name={field.fieldName}
                                      type={field.dataType === 'numeric' ? 'number' : 'text'}
                                      value={field.value}
                                      // defaultValue={field.value}
                                      // value={formObj[field.fieldName] ? formObj[field.fieldName] : ""}
                                      label={prop.levelName}
                                      readOnly
                                    />
                                  </FormControl>
                                )}

                                {field.type === 'textarea' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      id="standard-multiline-flexible"
                                      required={field.required === 'true' ? true : false}
                                      multiline
                                      name={field.fieldName}
                                      rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                                      value={field.value}
                                      // value={values.addressData[field.fieldName] ? values.addressData[field.fieldName] : ""}
                                      readOnly
                                      label={prop.levelName}
                                    />
                                  </FormControl>
                                )}
                                {field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      id="standard-basic"
                                      name={field.fieldName}
                                      value={field.defaultValue}
                                      label={prop.levelName}
                                      defaultValue={field.defaultValue}
                                      readOnly
                                    />
                                  </FormControl>
                                )}
                                {prop.iButtonRequired === 'true' && (
                                  <Tooltip title={prop.iButtonMessage} placement="top">
                                    <InfoOutlinedIcon
                                      style={{ fontSize: 'medium', marginTop: '23px', marginLeft: '-23px' }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            );
                          })}
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expandInvoiceDetails} elevation="none">
            <AccordionSummary
              className={classes.AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExapandInvoiceDetails(!expandInvoiceDetails)}>
              <Typography className={classes.heading}>Invoice Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={productList}
                      getOptionLabel={option => option.name}
                      value={formik.values.productData}
                      disabled={
                        query2.get('mode') === 'view' || formik.values.invoiceType === 'invoiceFromQuotation' ? true : false
                      }
                      renderInput={params => <TextField {...params} label={<span>Product <Asterisk/></span>} />}
                      name="productData"
                      onChange={handleProductChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={planList}
                      getOptionLabel={option => option.name}
                      value={formik.values.planData}
                      disabled={
                        query2.get('mode') === 'view' || formik.values.invoiceType === 'invoiceFromQuotation' ? true : false
                      }
                      renderInput={params => <TextField {...params} label={<span>Plan <Asterisk/></span>} />}
                      name="planData"
                      onChange={handlePlanChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={categoryList}
                      getOptionLabel={option => option.name}
                      value={formik.values.categorydata}
                      disabled={
                        query2.get('mode') === 'view' || formik.values.invoiceType === 'invoiceFromQuotation' ? true : false
                      }
                      renderInput={params => <TextField {...params} variant="standard" label="Categories" />}
                      name="categorydata"
                      onChange={handleCategorySelect}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper} elevation={0} className={classes.AccordionSummary}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Category</TableCell>
                          <TableCell>No. of members</TableCell>
                          <TableCell align="right">Premium Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formik.values.categorydata.map((prop, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell>{prop.name}</TableCell>
                              <TableCell>
                                {' '}
                                <TextField
                                  size="small"
                                  type="number"
                                  id="standard-basic"
                                  name="noOfMembers"
                                  disabled={
                                    query2.get('mode') === 'view' || formik.values.invoiceType === 'invoiceFromQuotation'
                                      ? true
                                      : false
                                  }
                                  value={prop.noOfMembers}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => {
                                    changeCategoryData(e, i);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  size="small"
                                  type="number"
                                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                  id="standard-basic"
                                  name="premiumAmount"
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  disabled={
                                    query2.get('mode') === 'view' || formik.values.invoiceType === 'invoiceFromQuotation'
                                      ? true
                                      : false
                                  }
                                  value={prop.premiumAmount}
                                  onChange={e => {
                                    changeCategoryData(e, i);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Total Premium Amount :{formik.values.totalPremiumAmount.toFixed(2)}{' '}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <table style={{ width: '100%' }}>
                    <tr style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid item xs={12} sm={6} md={3}>
                        <th></th>
                      </Grid>
                    </tr>
                    <tr style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={3}>
                        <td>Discount</td>
                      </Grid>
                      {/* <Grid item xs={3}>
                                              <td>
                                                  <FormControl className={classes.formControl}>
                                                      <InputLabel
                                                          id="demo-simple-select-label"
                                                          style={{ marginBottom: "0px" }}
                                                      >
                                                          Discount Type
                                                      </InputLabel>
                                                      <Select
                                                          labelId="demo-simple-select-label"
                                                          name="discountType"
                                                          id="demo-simple-select"
                                                          value={formik.values.discountType}
                                                          onChange={formik.handleChange}
                                                      >
                                                          <MenuItem value="PERCENTAGE">PERCENTAGE</MenuItem>;
                                                       </Select>
                                                  </FormControl>
                                              </td>

                                          </Grid> */}
                      <Grid item xs={6}>
                        <td>
                          <TextField
                            size="small"
                            type="number"
                            id="standard-basic"
                            name="discountValue"
                            value={formik.values.discountValue}
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onChange={handleDiscountChange}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                            label="discount percentage (%)"
                          />
                        </td>
                      </Grid>
                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <td>{formik.values.discountAmount.toFixed(2)}</td>
                      </Grid>
                    </tr>
                    <tr style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={3}>
                        <td>Loading</td>
                      </Grid>
                      {/* <Grid item xs={3}>
                                              <td>
                                                  <FormControl className={classes.formControl}>
                                                      <InputLabel
                                                          id="demo-simple-select-label"
                                                          style={{ marginBottom: "0px" }}
                                                      >
                                                          Loading Type
                                                      </InputLabel>
                                                      <Select
                                                          labelId="demo-simple-select-label"
                                                          name="loadingType"
                                                          id="demo-simple-select"
                                                          value={formik.values.loadingType}
                                                          onChange={formik.handleChange}
                                                      >
                                                          <MenuItem value="PERCENTAGE">PERCENTAGE</MenuItem>;
                                                       </Select>
                                                  </FormControl>
                                              </td>

                                          </Grid> */}
                      <Grid item xs={6}>
                        <td>
                          <TextField
                            size="small"
                            type="number"
                            id="standard-basic"
                            name="loadingValue"
                            value={formik.values.loadingValue}
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onChange={handleLoadingChange}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                            label="loading percentage (%)"
                          />
                        </td>
                      </Grid>
                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <td>{formik.values.loadingAmount.toFixed(2)}</td>
                      </Grid>
                    </tr>
                  </table>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={6}>
                  <FormGroup row>
                    {taxList.map((row, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={row.checked}
                            onChange={e => handleFieldChecked(e, i)}
                            name="checked"
                            color="primary"
                            disabled={query2.get('mode') === 'view' ? true : false}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                          />
                        }
                        label={row.name}
                      />
                    ))}
                  </FormGroup>
                </Grid>

                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Total After discount and loading :{formik.values.totalAmountWithoutTax.toFixed(2)}
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <TableContainer component={Paper} elevation="none" className={classes.AccordionSummary}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tax name</TableCell>
                          <TableCell>Tax value</TableCell>
                          <TableCell>Tax type</TableCell>
                          <TableCell align="right">Tax Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {taxList.map((row, i) => {
                          return (
                            row.checked && (
                              <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.value}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell align="right">{row.taxVal.toFixed(2)}</TableCell>
                              </TableRow>
                            )
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ fontWeight: 'bold' }}>Grand Total :{formik.values.totalAmountWithTax.toFixed(2)}</span>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {query2.get('mode') !== 'view' ? (
                    <Button variant="contained" color="secondary" onClick={handleOpenAgentModal}>
                      Search Agent
                    </Button>
                  ) : null}

                  <InvoiceAgentModal
                    agentsList={agentsList}
                    handleCloseAgentModal={handleCloseAgentModal}
                    openAgentModal={openAgentModal}
                    setAgentsList={setAgentsList}
                    handleAgentModalSubmit={handleAgentModalSubmit}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <TableContainer component={Paper} elevation="none" className={classes.AccordionSummary}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Agent name</TableCell>
                          <TableCell>Commission value</TableCell>
                          <TableCell align="right">Final value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {agentsList.map((row, i) => (
                          <TableRow key={row.agentId}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                type="number"
                                id="standard-basic"
                                name="commission"
                                value={agentsList[i]?.commission}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => {
                                  changeCommision(e, i);
                                }}
                                label="Commission value (%)"
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">{Number(row.finalValue).toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {/* <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                              
                          </Grid> */}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
      <Paper elevation="none">
        <Box p={3} my={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {query2.get('mode') !== 'view' ? (
                <Button variant="contained" color="primary" style={{ marginRight: '5px' }} type="submit">
                  Save
                </Button>
              ) : null}
              <Button variant="contained" color="primary" style={{ marginRight: '5px' }}>
                Update
              </Button>
              <Button
                variant="text"
                color="primary"
                className={`${query2.get('mode') === 'view' ? 'p-button' : 'p-button-text'}`}
                onClick={handleClose}>
                {query2.get('mode') === 'view' ? 'Ok' : 'Cancel'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </form>
  );
}
