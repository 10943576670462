import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ProductService } from '../../remote-api/api/product-services';
import '../styles.css';
import ProductBasicDetailsComponent from './basic-details/product-basic-details.component';
import BenifitDesignComponent from './product-rule-design/benifit-design.component';
import PreviewComponent from './preview/preview.component';
import ServiceDesignComponent from './service-design/service.design.component';
import { Button } from 'primereact/button';

const productservice = new ProductService();

const useStyles = theme => ({
  prodManageRoot: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  labelContainer: {
    borderRadius: 4,
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important',
  },
  stepperContent: {
    borderRadius: 4,
    backgroundColor: 'inherit',
    margin: '15px 0',
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
  steperAction: {
    textAlign: 'right',
    padding: '20px 14px',
    paddingTop: 0,
  },
});

const ColorlibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles(theme => ({
  iconRoot: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: theme.palette.primary.contrastText,
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    color: 'rgba(255,255,255,.7)',
  },
}));

function getSteps() {
  return ['Basic Details', 'Benefit Design', 'Service Design', 'Preview and Save'];
}

function getStepContent(
  step,
  handleNext,
  productDetails,
  benefitStructure = [],
  updateBasiDetailsFnc,
  updateBenefitStructureFnc,
  updateServiceDesignDetailsFnc,
) {
  switch (parseInt(step)) {
    case 0:
      return (
        <ProductBasicDetailsComponent
          handleNextStep={handleNext}
          productDetails={productDetails}
          updateBasiDetails={updateBasiDetailsFnc}
        />
      );
    case 1:
      return (
        <BenifitDesignComponent
          handleNextStep={handleNext}
          productDetails={productDetails}
          updateBenefitStructureFnc={updateBenefitStructureFnc}
        />
      );
    case 2:
      return (
        <ServiceDesignComponent
          productDetails={productDetails}
          handleNextStep={handleNext}
          benefitStructure={benefitStructure}
          updateServiceDesignDetails={updateServiceDesignDetailsFnc}
        />
      );
      case 3:
      return <PreviewComponent productDetails={productDetails} />;

      default: return null;
  }
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.iconRoot, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      <strong>{props.icon}</strong>
    </div>
  );
}

const steps = getSteps();

class ProductManagementForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: this.useQuery().get('step'),
      productDetails: {},
      benefitStructure: [],
    };
  }

  useQuery() {
    return new URLSearchParams(this.props.location.search);
  }
  componentDidMount() {
    if (localStorage.getItem('productId')) {
      localStorage.removeItem('productId');
    }
    if (this.props.match.params.productId) {
      localStorage.setItem('productId', this.props.match.params.productId);
      productservice.getProductDetails(this.props.match.params.productId).subscribe(res => {
        let step = this.useQuery().get('step');
        // if (step == null || step == undefined || isNaN(step) || !(step >= 0 && step < steps.length - 1)) {
        //   step = 0;
        // }
        this.setState({
          ...this.state,
          productDetails: res,
          activeStep: parseInt(this.useQuery().get('step')),
        });
      });
    }
  }

  handleNext = () => {
    const { history } = this.props;
    this.setState({
      ...this.state,
      activeStep: parseInt(this.useQuery().get('step')) + 1,
    });
    history.push(`/products/${localStorage.getItem('productId')}?mode=edit&step=${parseInt(this.useQuery().get('step')) + 1}`);
  };

  handleBack = () => {
    const { history } = this.props;
    this.setState({
      ...this.state,
      activeStep: parseInt(this.useQuery().get('step')) - 1,
    });
    history.push(`/products/${localStorage.getItem('productId')}?mode=edit&step=${parseInt(this.useQuery().get('step')) - 1}`);
  };

  handleReset = () => {
    this.setState({
      ...this.state,
      activeStep: 0,
    });
  };

  updateBasiDetailsFnc = basicDetails => {
    this.setState({
      ...this.state,
      productDetails: {
        ...this.state.productDetails,
        productBasicDetails: {
          productBasicDetails: this.state.productDetails.productBasicDetails,
          ...basicDetails.productBasicDetailsForm,
        },
      },
    });
  };

  updateBenefitStructureFnc = structureObj => {
    this.setState({
      ...this.state,
      benefitStructure: structureObj,
    });
  };

  updateServiceDesignDetailsFnc = serviceDesignDetails => {
    this.setState({
      ...this.state,
      productDetails: {
        ...this.state.productDetails,
        productServices: {
          services: [...serviceDesignDetails],
        },
      },
    });
  };

  render() {
    const { classes } = this.props;
    const { activeStep, productDetails, benefitStructure } = this.state;
    return (
      <div className={classes.prodManageRoot}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stepper
              alternativeLabel
              activeStep={parseInt(this.useQuery().get('step'))}
              connector={<ColorlibConnector />}
              className={classes.labelContainer}>
              {steps.map(label => {
                return (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className={classes.stepperContent}>
              {parseInt(this.useQuery().get('step')) === steps.length ? (
                <div className={classes.steperAction}>
                  <Typography className={classes.instructions}>All steps completed</Typography>
                  <Button onClick={this.handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography component={'span'} className={classes.instructions}>
                    {getStepContent(
                      parseInt(this.useQuery().get('step')),
                      this.handleNext,
                      productDetails,
                      benefitStructure,
                      this.updateBasiDetailsFnc,
                      this.updateBenefitStructureFnc,
                      this.updateServiceDesignDetailsFnc,
                    )}
                  </Typography>
                  <div className={classes.steperAction}>
                    <Button
                      disabled={parseInt(this.useQuery().get('step')) === 0}
                      onClick={this.handleBack}
                      className={`p-button-text ${classes.backButton}`}>
                      Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                      {parseInt(this.useQuery().get('step')) === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(ProductManagementForm));
