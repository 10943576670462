import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ClaimsListComponent from './claim.list.component';
import ClaimsDetails from './cliam.details.component';
import CreditClaimsDetails from './credit.details.component';
import CreditListComponent from './credit.list.component';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Credit() {
  const history = useHistory();
  const query = useQuery();
  const [title, setTitle] = React.useState();

  return (
    <div>
      {query.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
            height: '2em',
            color: '#000',
            fontSize: '18px',
            fontWeight: 600,
          }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
           Credit Claim Management
          </span>
          <span>{title && `${title}`}</span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get('mode')) {
          case 'viewList':
            return <CreditListComponent />;
          case 'create':
            return <CreditClaimsDetails setTitle={setTitle}/>;
          case 'edit':
            return <CreditClaimsDetails setTitle={setTitle}/>;
          default:
            history.push('/claims/credit?mode=viewList');
        }
      })()}
    </div>
  );
}
