import DateFnsUtils from '@date-io/date-fns';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import 'date-fns';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Yup from 'yup';
import { ProvidersService } from '../../../remote-api/eo2v2-remote-api';
import { ReimbursementService } from '../../../remote-api/api/claims-services';
import { Eo2v2DataGrid } from '../../../shared-components';
import { getDateElements } from '../../../../@jumbo/utils/dateHelper';



// const data$ = new Observable(subscriber => {
//   subscriber.next(sampleData);
// });

// const dataSource$ = () => {
//   return data$.pipe(
//     map(data => {
//       data.content = data;
//       return data;
//     })
//   );
// };


const useStyles = makeStyles((theme) => ({
  header: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px 10px 0px 0px',
    background: '#0edb8a',
    padding: 20,
    borderBottom: 'none'
  },
  customStyle: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '0px 0px 10px 10px',
    background: '#ffffff',
    padding: 20,
    borderTop: 'none'
  },

  headerText: {
    fontSize: '16px',
    fontWeight: 'Bold',
    color: '#002776'
  },
  subheader: {
    fontSize: '12px',
    fontWeight: 'Bold'
  },
  body: {
    fontSize: '12px',
    fontWeight: 'Bold'
  },
  dropdownsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    minWidth: 182,
  },
  dropdown: {
    marginLeft: theme.spacing(2),
    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

const validationSchema = Yup.object().shape({
  selectedDate: Yup.date().nullable().required('Date is required'),
  selectedProvider: Yup.string().required('Provider is required'),
});

const ReadyForPaymentListComponent = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const providerService = new ProvidersService();

  const [providerList, setProviderList] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);

  const formik = useFormik({
    initialValues: {
      selectedDate: new Date(),
      selectedProvider: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      // submitHandler(values);
    },
  });

  const handleMembershipClick = (rowData, field) => {
    history.push(`/claim-detail/${rowData.id}?mode=viewOnly`);
  };

  const columnsDefinations = [
    {
      field: 'serial',
      headerName: 'SL#',
      body: (rowData, data) => data.rowIndex + 1,
      style: { width: '4rem' }
    },
    {
      field: 'memberShipNo',
      headerName: 'MEMBERSHIP NO',
      body: (rowData) => (
        <span
          // style={{ cursor: 'pointer', textDecoration: 'underline' }}
          // onClick={() => handleMembershipClick(rowData, 'membershipNo')}
        >
          {rowData.memberShipNo}
        </span>
      ),

    },
    {
      field: 'claimNo',
      headerName: 'CLAIM NO.',
      body: (rowData) => (
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => handleMembershipClick(rowData, 'claimNo')}
        >
          {rowData.id}
        </span>
      ),

    },
    { field: 'createdDate', headerName: 'CLAIM DATE' },
    { field: 'expectedDOA', headerName: 'ADMISSION DATE' },
    { field: 'expectedDOD', headerName: 'DISCHARGE DATE' },
    { field: 'claimedAmount', headerName: 'CLAIMED AMOUNT' },
    {

      field: 'providerName', headerName: 'PROVIDERS NAME',
      body: (rowData) => {
        return (
          <ul style={{ listStyle: 'disc' }}>
            {rowData?.providerName?.map((name, index) => (
              <li key={index}>&bull; {name}</li>
            ))}
          </ul>
        );
      }
    },
    { field: 'billAmount', headerName: 'BILL AMOUNT' },
  ];

  let ps$ = providerService.getProviders();
  const reimbursementService = new ReimbursementService();


  useEffect(() => {
    const subscription = ps$.subscribe(result => {
      const filteredProviders = result.content.filter(ele => !ele.blackListed);
      setProviderList(filteredProviders);
      return () => subscription.unsubscribe();
    });
  }, [])

  const dataSource$ = (
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
    },
  ) => {
    pageRequest.sort = ['rowCreatedDate dsc'];
    pageRequest.claimStatus = ["READY_FOR_PAYMENT"];
    // if (pageRequest.searchKey) {
    //   pageRequest['providerId'] = pageRequest.searchKey;
    // }

    return reimbursementService.getAllReimbursements(pageRequest).pipe(
      map(data => {
        let content = data.content;
        let records = content.map(item => {

          const providerNames = item?.providers?.map(providerId => providerList
              .find(provider => provider.id === providerId)?.name)
            .filter(name => name !== undefined || name !== '');

          const totalEstimatedCost = item.benefitsWithCost.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.estimatedCost;
          }, 0);

          const invoicesAmount = item.invoices.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.invoiceAmount;
          }, 0);

          item['createdDate'] = `${getDateElements(item.createdDate).date.numerical}`
          item['expectedDOA'] = `${getDateElements(item.expectedDOA).date.numerical}`
          item['expectedDOD'] = `${getDateElements(item.expectedDOD).date.numerical}`
          item['providerName'] = providerNames;
          item['claimedAmount'] = totalEstimatedCost;
          item['billAmount'] = invoicesAmount;

          return item;
        });
        data.content = records;
        return data;
      }),
    );
  };






  const openEditSection = provider => {
    history.push(`/endorsements/${provider.id}?mode=edit`);
  };
  const handleOpen = () => {
    history.push('/endorsements?mode=create');
  };
  const handleSelectedRows = selectedClaim => {
  }
  const xlsColumns = [
    'serial',
    'memberShipNo',
    'claimNo',
    'createdDate',
    'expectedDOA',
    'expectedDOD',
    'claimedAmount',
    'providerName',
    'billAmount'
  ];
  
  const configuration = {
    // enableSelection: true,
    scrollHeight: '300px',
    pageSize: 10,
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      text: 'Ready For Payment',
      enableGlobalSearch: true,
      searchText: 'Search by Provider id',
      // onSelectionChange: handleSelectedRows,
    },
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {/* <form onSubmit={formik.handleSubmit}>
          <Grid container alignItems='flex-end' justifyContent="space-around" >
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="MMMM/yyyy"
                  id="selectedDate"
                  label="Claims inwarded in"
                  color='inherit'
                  value={formik.values.selectedDate}
                  name="selectedDate"
                  onChange={date => {
                    formik.setFieldValue('selectedDate', date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  views={['year', 'month']}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  maxDate={new Date()}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label" >Provider</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="selectedProvider"
                  name="selectedProvider"
                  color='primary'
                  value={formik.values.selectedProvider}
                  onChange={e => {
                    formik.handleChange(e)
                  }}
                >
                  {providerList.map(ele => {
                    return (
                      <MenuItem key={ele.id} value={ele.id}>
                        {ele.providerBasicDetails.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>
                  {formik.touched.selectedProvider && formik.errors.selectedProvider}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <Button variant='outlined' type='submit' color='inherit'>Go</Button>
            </Grid>
          </Grid>
        </form> */}
      </Grid>
      <Grid item xs={12}>
        {/* {showDataGrid && ( */}
          <Eo2v2DataGrid
            $dataSource={dataSource$}
            columnsDefination={columnsDefinations}
            onEdit={openEditSection}
            config={configuration}
          />
        {/* )} */}
      </Grid>
    </Grid>
  );
};

export default ReadyForPaymentListComponent;
