import { Button } from 'primereact/button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { RolesService, UsersService } from '../../remote-api/api/user-management-service';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const rolesService = new RolesService();
const usersService = new UsersService();

let rolesService$ = rolesService.getRoles();
let rolesLocation$ = rolesService.getLocation();

const useStyles = theme => ({
  root: {
    padding: 20,
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
  formControl: {
    minWidth: 182,
  },
});

const initForm = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  roles: [],
  location: '',
  password: '',
};

const usersSchema = Yup.object().shape({
  userName: Yup.string().required('Username is required'),
  roles: Yup.array().min(1, 'Select atleast one role'),
  // location: Yup.array().min(1, 'Select atleast one role'),
  // location: Yup.string().required('Username is required'), //changes made while showoing demo to james on octber 18
});

class UsersFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userForm: { ...initForm },
      rolesList: [],
      rolesLocation: [],
    };
  }

  componentDidMount() {
    rolesService$.subscribe(response => {
      this.setState({
        ...this.state,
        rolesList: response.map(({ name }) => ({ id: name, name })),
      });
    });
    rolesLocation$.subscribe(response => {
      this.setState({
        ...this.state,
        rolesLocation: response.map(({ id, code, name }) => ({ id, code, name })),
      });
    });
    if (this.props.match.params.userName) {
      usersService.getUserDetails(this.props.match.params.userName).subscribe(res => {
        this.setState({
          ...this.state,
          userForm: { ...res, roles: res.roles.map(r => ({ id: r, name: r })) },
        });
      });
    }
  }

  handleClose = () => {
    this.props.history.push('/user-management/users');
  };

  render() {
    const { classes } = this.props;
    const { rolesList, rolesLocation, userForm } = this.state;

    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Users Management: {this.props.match.params.userName ? 'Edit' : 'Add'}
        </Typography>
        <Paper elevation="none">
          <div className={classes.root}>
            <Formik
              enableReinitialize={true}
              initialValues={{ ...userForm }}
              validationSchema={usersSchema}
              onSubmit={(values, { resetForm }) => {
                const payload = {
                  ...initForm,
                  ...values,
                  roles: values.roles.map(item => item.name),
                  // location: values.location.map(item => item.code),
                  location: ''
                };

                if (this.props.match.params.userName) {
                  usersService.updateUsers(this.props.match.params.userName, payload).subscribe(res => {
                    if (res.status) {
                      this.handleClose();
                    }
                  });
                } else {
                  usersService.saveUsers(payload).subscribe(res => {
                    if (res.status) {
                      resetForm();
                      this.handleClose();
                    }
                  });
                }
              }}>
              {({ touched, errors, handleSubmit, handleChange, values, setFieldValue }) => {
                const allSelected = rolesList.length > 0 && values.roles.length === rolesList.length;
                console.log('handle submit', values)
                // const allSelect = rolesLocation?.length > 0 && values?.location?.length === rolesLocation?.length;
                const handleRoleChange = (e, val) => {
                  let selectedRoles = val;
                  const isSelecAll = selectedRoles.some(item => item.id === 'selectall');
                  if (isSelecAll) {
                    if (rolesList.length > 0 && rolesList.length === values.roles.length) {
                      selectedRoles = [];
                    } else {
                      selectedRoles = this.state.rolesList;
                    }
                  }
                  setFieldValue('roles', selectedRoles);
                };

                // const handleRoleChanged = (e, val) => {
                //   let selectedLocation = val;
                //   const isSelecAll = selectedLocation.some(item => item.id === 'selectall');
                //   if (isSelecAll) {
                //     if (rolesLocation.length > 0 && rolesLocation.length === values.roles.length) {
                //       selectedLocation = [];
                //     } else {
                //       selectedLocation = this.state.rolesLocation;
                //     }
                //   }
                //   setFieldValue('location', selectedLocation[0]?.code || ''); 
                // };
                const autocompleteFilterChange = (options, state) => {
                  if (state.inputValue) {
                    return options.filter(item => item.name.toLowerCase().indexOf(state.inputValue) > -1);
                  }
                  return [{ id: 'selectall', name: 'Select all' }, ...options];
                };

                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <TextField name="firstName" label="First Name" value={values.firstName} onChange={handleChange} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField name="lastName" label="Last Name" value={values.lastName} onChange={handleChange} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="userName"
                          label="Username"
                          value={values.userName}
                          onChange={handleChange}
                          required
                          disabled={!!this.props.match.params.userName}
                          error={touched.userName && Boolean(errors.userName)}
                          helperText={touched.userName && errors.userName}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="email"
                          label="Email Id"
                          value={values.email}
                          onChange={handleChange}
                          disabled={!!this.props.match.params.userName}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid container xs={3}>
                        <Grid item xs={3}>
                          <FormControl
                            className={classes.formControl}
                            required
                            error={touched.roles && Boolean(errors.roles)}>
                            <Autocomplete
                              className={classes.benifitAutoComplete}
                              multiple
                              required
                              value={values.roles}
                              onChange={handleRoleChange}
                              id="checkboxes-tags-role"
                              filterOptions={autocompleteFilterChange}
                              options={rolesList}
                              disableCloseOnSelect
                              getOptionLabel={option => option.name}
                              getOptionSelected={(option, value) => option.id === value.id}
                              renderOption={(option, { selected }) => {
                                const selectedOpt = (option.id === 'selectall' && allSelected) || selected;
                                return (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8, color: '#626bda' }}
                                      checked={selectedOpt}
                                    />
                                    {option.name}
                                  </React.Fragment>
                                );
                              }}
                              renderInput={params => <TextField {...params} label="Roles" placeholder="Select Roles" />}
                            />
                            {touched.roles && Boolean(errors.roles) && (
                              <FormHelperText>{touched.roles && errors.roles}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
{/* 
                      <Grid container xs={3}>
                        <Grid item xs={3}>
                          <FormControl
                            className={classes.formControl}
                            required
                            error={touched.location && Boolean(errors.location)}
                            >
                            <Autocomplete
                              className={classes.benifitAutoComplete}
                              
                              
                              value={values.location || ''}
                              onChange={handleRoleChanged}
                              id="checkboxes-tags-role"
                              filterOptions={autocompleteFilterChange}
                              options={rolesLocation}
                              disableCloseOnSelecty
                              disabled
                              getOptionLabel={option => option.name}
                              getOptionSelected={(option, value) => option.id === value.id}
                              renderOption={(option, { selected }) => {
                                const selectedOpt = (option.id === 'selectall' && allSelect) || selected;
                                return (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8, color: '#626bda' }}
                                      checked={selectedOpt}
                                    />
                                    {option.name}
                                  </React.Fragment>
                                );
                              }}
                              renderInput={params => (
                                <TextField {...params} label="Location" placeholder="Select Location" />
                              )}
                            />
                            {touched.roles && Boolean(errors.roles) && (
                              <FormHelperText>{touched.roles && errors.roles}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid> */}
                      <Grid container xs={3}>
                        <Grid item xs={3}>
                          <TextField
                            name="password"
                            label="Password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            required
                            disabled={!!this.props.match.params.password}
                            error={touched.userName && Boolean(errors.password)}
                            helperText={touched.userName && errors.password}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: '10px' }}>
                      <Grid item xs={3}>
                        <Button type="submit" variant="contained" color="primary">
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          style={{ marginLeft: 15 }}
                          className="p-button-text"
                          onClick={() => this.handleClose()}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(UsersFormComponent));
