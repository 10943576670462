import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import React, { useEffect, useRef, useState } from 'react';
import { ProviderTypeService } from '../../remote-api/api/master-services';
import { Button } from 'primereact/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Toast } from 'primereact/toast';
import { ArrowBack, ArrowForward, Delete, Edit } from '@material-ui/icons';
import { Pagination, PaginationItem } from '@material-ui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const providertypeservice = new ProviderTypeService();
let pt$ = providertypeservice.getProviderTypes();
let ptq$ = providertypeservice.getProviderOwnerTypes();
let ptr$ = providertypeservice.getProviderCategory();

export default function ProviderAddressConfig() {
  const [active, setActive] = React.useState('');
  const [showInput, setShowInput] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [providerTypes, setProviderTypes] = React.useState([]);
  const [providerOwnerTypes, setProviderOwnerTypes] = React.useState([]);
  const [categoryTypes, setCategoryTypes] = React.useState([]);

  const [mainData, setMainData] = useState([]);
  const [handleEditName, setHandleEditName] = useState('');
  // const[editId,setEditId] = useState();
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const [idEdit, setEditId] = useState();
  // setthe get provider type value
  const useObservable = (observable, setter) => {
    // useEffect(() => {
    //   let subscription = observable.subscribe(result => {
    //     setter(result.content);
    //   });
    //   return () => subscription.unsubscribe();
    // }, [observable, setter]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = id => {
    setEditId(id);
    setOpen(true);
  };

  // hold the get data in the state for a dropdown
  useObservable(pt$, setProviderTypes);
  useObservable(ptq$, setProviderOwnerTypes);
  useObservable(ptr$, setCategoryTypes);

  // make an active button on proposer section
  const makeActive = select => {
    if (select === 1) {
      setActive(1);
      setShowInput(false);
    }
    if (select === 2) {
      setActive(2);
      setShowInput(false);
    }
    if (select === 3) {
      setActive(3);
      setShowInput(false);
    }
  };

  const handleAddFieldClick = () => {
    setShowInput(true);
    setSelectedOption(null);
  };

  const handleSelectChange = val => {
    setSelectedOption(val);
  };

  const fetchProviderLabel = () => {
    try {
      providertypeservice.getProviderLabel().subscribe(res => {
        setMainData(res);
      });
    } catch (error) {
      console.error('Error saving the value:', error);
    }
  };

  const fetchProviderOwnerType = () => {
    try {
      providertypeservice.getProviderOwner().subscribe(res => {
        setMainData(res);
      });
    } catch (error) {
      console.error('Error saving the value:', error);
    }
  };

  const fetchProviderType = () => {
    try {
      providertypeservice.getProviderType().subscribe(res => {
        setMainData(res);
      });
    } catch (error) {
      console.error('Error saving the value:', error);
    }
  };

  const handleSaveClick = async () => {
    if (selectedOption) {
      const payload = {
        name: selectedOption,
      };
      try {
        providertypeservice.addProviderType(payload).subscribe(res => {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'SucessFully Added Provider Type',
            life: 2000,
          });
          setShowInput(false);
        });
        setTimeout(() => {
          fetchProviderType();
        }, 1000);
        setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else {
      console.log('Input value is empty');
    }
  };

  const handleSaveClickOwnerType = async () => {
    if (selectedOption) {
      const payload = {
        name: selectedOption,
      };
      try {
        providertypeservice.addProviderOwner(payload).subscribe(res => {
          if (res?.id) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'SucessFully Added Provider Owner Type',
              life: 2000,
            });
            setShowInput(false);
          }
        });
        setTimeout(() => {
          fetchProviderOwnerType();
        }, 1000);
        setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else {
      console.log('Input value is empty');
    }
  };

  // const fetchFundData = () => {
  //   try {
  //     providertypeservice.getProviderList(0).subscribe(res => {
  //       // setFundData(res?.content)
  //     });

  //   } catch (error) {
  //     console.error('Error saving the value:', error);
  //   }
  // };

  const handleEditApi = id => {
    if (active == 1) {
      try {
        handleEditName.length > 0 &&
          providertypeservice.editProviderList(id, handleEditName).subscribe(res => {
            // setFundData(res?.content)
            // fetchFundData();
            fetchProviderLabel();
            handleClose();
            setHandleEditName('');
          });

        // setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else if (active == 2) {
      try {
        handleEditName.length > 0 &&
          providertypeservice.editProviderType(id, handleEditName).subscribe(res => {
            // setFundData(res?.content)
            // fetchFundData();
            fetchProviderType();
            handleClose();
            setHandleEditName('');
          });

        // setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else {
      try {
        handleEditName.length > 0 &&
          providertypeservice.editOwnerProviderType(id, handleEditName).subscribe(res => {
            // setFundData(res?.content)
            // fetchFundData();

            fetchProviderOwnerType();
            handleClose();
            setHandleEditName('');
          });

        // setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    }
  };

  const handleDelete = id => {
    if (active == 1) {
      try {
        providertypeservice.deleteProviderList(id).subscribe(res => {
          // setFundData(res?.content)
          // fetchFundData();
          fetchProviderLabel();
        });

        // setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else if (active == 2) {
      try {
        providertypeservice.deleteProviderTypesList(id).subscribe(res => {
          // setFundData(res?.content)
          // fetchFundData();
          fetchProviderType();
        });

        // setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else {
      try {
        providertypeservice.deleteProviderOwnerTypesList(id).subscribe(res => {
          // setFundData(res?.content)
          // fetchFundData();
          fetchProviderOwnerType();
        });

        // setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    }
  };
  const handleSaveClickCategory = async () => {
    if (selectedOption) {
      const payload = {
        name: selectedOption,
      };
      try {
        providertypeservice.addProviderCategoryType(payload).subscribe(res => {
          if (res?.id) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'SucessFully Added Provider Label',
              life: 2000,
            });
            setShowInput(false);
          }
        });
        setTimeout(() => {
          fetchProviderLabel();
        }, 1000);
        setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else {
      console.log('Input value is empty');
    }
  };

  const handleSaveClicked = async () => {
    if (selectedOption) {
      const payload = {
        name: selectedOption,
      };
      try {
        providertypeservice.addProviderOwnerType(payload).subscribe(res => {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'SucessFully Added Provider OwnerType',
            life: 2000,
          });
          setShowInput(false);
        });

        setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else {
      console.log('Input value is empty');
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Edit />
              <h1 style={{ fontSize: '1.5rem' }}>Edit Name</h1>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 20, marginTop: '1.5rem' }}>
              <TextField
                onChange={e => setHandleEditName(e.target.value)}
                id="outlined-basic"
                value={handleEditName}
                label="Edit Name"
                variant="outlined"
                style={{ width: '400px' }}
              />
              <Button
                variant="contained"
                style={{ padding: '15px', fontSize: '1rem' }}
                onClick={() => handleEditApi(idEdit)}>
                Save
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button> */}
        </DialogActions>
      </Dialog>
      <Paper elevation="none">
        <Box p={3} my={2}>
          <>
            <Grid container item xs={12}>
              <Button
                style={active === 1 ? { color: 'white', backgroundColor: '#41e276', margin: '2px' } : { margin: '2px' }}
                onClick={() => {
                  makeActive(1);
                  fetchProviderLabel();
                }}>
                PROVIDER LABEL
              </Button>
              <Button
                style={active === 2 ? { color: 'white', backgroundColor: '#41e276', margin: '2px' } : { margin: '2px' }}
                onClick={() => {
                  makeActive(2);
                  fetchProviderType();
                }}>
                PROVIDER TYPE
              </Button>
              <Button
                style={active === 3 ? { color: 'white', backgroundColor: '#41e276', margin: '2px' } : { margin: '2px' }}
                onClick={() => {
                  makeActive(3);
                  fetchProviderOwnerType();
                }}>
                PROVIDER OWNER TYPE
              </Button>
            </Grid>
            <span></span>&nbsp;
            {active === 1 && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column', paddingRight: '20px' }} mt={4}>
                  {!showInput && (
                    <Button variant="contained" color="primary" onClick={handleAddFieldClick}>
                      Add New
                    </Button>
                  )}

                  {showInput && (
                    <>
                      <FormControl variant="outlined" margin="normal" style={{ width: '200px', marginTop: '-12px' }}>
                        <TextField
                          label="Provider Label"
                          value={selectedOption}
                          onChange={event => {
                            // Restrict input to alphabets only
                            const newValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
                            handleSelectChange(newValue); // Call your existing handler with the filtered value
                          }}
                          variant="outlined"
                          margin="normal"
                        />
                      </FormControl>

                      <Button
                        style={{ display: 'flex' }}
                        variant="contained"
                        color="secondary"
                        onClick={handleSaveClickCategory}>
                        Save
                      </Button>
                    </>
                  )}
                </Box>
              </>
            )}
            {active === 2 && (
              <Box sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column', paddingRight: '20px' }} mt={4}>
                {!showInput && (
                  <Button variant="contained" color="primary" onClick={handleAddFieldClick}>
                    Add New
                  </Button>
                )}

                {showInput && (
                  <>
                    <FormControl variant="outlined" margin="normal" style={{ width: '200px', marginTop: '-12px' }}>
                      <TextField
                        label="Provider Type"
                        value={selectedOption}
                        onChange={event => {
                          // Restrict input to alphabets only
                          const newValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                          handleSelectChange(newValue); // Call your existing handler with the filtered value
                        }}
                        variant="outlined"
                        margin="normal"
                      />
                    </FormControl>

                    <Button style={{ display: 'flex' }} variant="contained" color="secondary" onClick={handleSaveClick}>
                      Save
                    </Button>
                  </>
                )}
              </Box>
            )}
            {active === 3 && (
              <Box sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column', paddingRight: '20px' }} mt={4}>
                {!showInput && (
                  <Button variant="contained" color="primary" onClick={handleAddFieldClick}>
                    Add New
                  </Button>
                )}
                {showInput && (
                  <>
                    <FormControl variant="outlined" margin="normal" style={{ width: '200px', marginTop: '-12px' }}>
                      <TextField
                        label="Provider Owner Type"
                        value={selectedOption}
                        onChange={event => {
                          // Restrict input to alphabets only
                          const newValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                          handleSelectChange(newValue); // Call your existing handler with the filtered value
                        }}
                        variant="outlined"
                        margin="normal"
                        type="text"
                      />
                    </FormControl>

                    <Button
                      style={{ display: 'flex' }}
                      variant="contained"
                      color="secondary"
                      onClick={handleSaveClickOwnerType}>
                      Save
                    </Button>
                  </>
                )}
              </Box>
            )}
            {mainData?.content?.length > 0 && (
              <>
                {' '}
                <Box sx={{ marginTop: '10px' }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="center">Code</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mainData?.content?.map(item => {
                          return (
                            <TableRow>
                              <TableCell>{item?.name}</TableCell>
                              <TableCell align="center">{item?.code}</TableCell>
                              <TableCell align="center">
                                <Edit
                                  style={{ marginRight: '25px', cursor: 'pointer' }}
                                  onClick={() => handleClickOpen(item?.id)}
                                />
                                <Delete onClick={() => handleDelete(item?.id)} style={{ cursor: 'pointer' }} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
                  <Pagination
                    count={mainData?.totalPages}
                    renderItem={item => <PaginationItem slots={{ previous: ArrowBack, next: ArrowForward }} {...item} />}
                  />
                </Box>
              </>
            )}
          </>
        </Box>
      </Paper>
    </>
  );
}
